import { Card, Container, Divider, Grid, GridColumn, GridRow, Segment } from "semantic-ui-react"

function App() {

  const labelColWidth = 4
  const dataColWidth = 12

  const addressLine1 = "Unit 2 / 7 Leatherwood Grove,"
  const addressLine2 = "MORISSET NSW 2264,"
  const addressLine3 = "AUSTRALIA"

  const emailAddress = "brandon.lane6@gmail.com"
  const phoneNum = "+61 428097246"


  return (
    <div className="App">
      <div className="MainCard">
      <Card centered fluid>
        <Card.Content textAlign="center">
          <Card.Header>Owner Information</Card.Header>
          <Card.Meta>Please get in contact</Card.Meta>
        </Card.Content>
        <Card.Content>
          <Card.Description textAlign='left'>

            <Grid>
              <GridRow columns={2} >
                <GridColumn width={labelColWidth}>
                  <h4>Name</h4>
                </GridColumn>

                <GridColumn textAlign='left' >
                  Brandon Lane
                </GridColumn>
              </GridRow>

              <GridRow columns={2}>
                <GridColumn width={labelColWidth}>
                  <h4>Address</h4>
                </GridColumn>
                
                <GridColumn rows={3} textAlign='left' width={dataColWidth}>

                  <GridRow>
                    {addressLine1}
                  </GridRow>
                  <GridRow>
                    {addressLine2}
                  </GridRow>
                  <GridRow>
                    {addressLine3}
                  </GridRow>
                  
                </GridColumn>
                
              </GridRow>

              <GridRow columns={2}>
                <GridColumn width={labelColWidth}>
                  <h4>Phone</h4>
                </GridColumn>
                
                <GridColumn textAlign='left'>
                  <a href={'tel:' + phoneNum}>{phoneNum}</a>
                </GridColumn>
              </GridRow>

              <GridRow columns={2}>
                <GridColumn width={labelColWidth}>
                  <h4>Email</h4>
                </GridColumn>
                
                <GridColumn textAlign='left' >
                  <a href={'mailto:' + emailAddress + '?subject=Lost Luggage'}>{emailAddress}</a>
                </GridColumn>
              </GridRow>

            </Grid>


            
          </Card.Description>
        </Card.Content>
        
      </Card>
      </div>
    </div>
  );
}

export default App;
